<template>
  <div class='register'>
    <div class='uxg'>Please fill-in your information</div>
    <div class='reg'>
      <div class='hss'>
        <input-element class='nrt'
          type='text'
          label='First Name'
          v-model='inputs.firstName'/>
        <input-element class='nrt'
          type='text'
          label='Last Name'
          v-model='inputs.lastName'/>
      </div>
      <div class='hss'>
        <mobile-input-component class='nrt'
          label='Mobile Number'
          v-model='inputs.mobileNumber'
          :error='isMobileNumberValid ? "" : "Invalid number"'/>
        <input-element class='nrt'
          type='email'
          label='Working Email Address'
          v-model='inputs.email'
          :error='isEmailValid ? "" : "Invalid email"'/>
      </div>
    </div>
    <div class='pcs'>
      <button-element
        :disabled='registering'
        @click='register'>
        Continue
        <progress-element class='rwy'
          :active='registering'/>
      </button-element>
    </div>
    <flash-message-component
      ref='errorDialog'
      type='error'>
      <template v-if='errorCode === "missing"'>Please fill in all information.</template>
      <template v-if='errorCode === "invalid-email"'>The email address is invalid.</template>
      <template v-if='errorCode === "weak-password"'>The password must be 6 characters long or more.</template>
      <template v-if='errorCode === "email-already-in-use"'>The email address is already in use by another account.</template>
      <template v-if='errorCode === "unknown"'>Oops! Something went wrong. Please try again later.</template>
    </flash-message-component>
  </div>
</template>

<script>
  import { computed, ref } from 'vue'
  import { useRouter } from 'vue-router'
  import { useStore } from 'vuex'
  import FlashMessage from '@/components/flash-message/flash-message.vue'
  import MobileInput from '@/components/mobile-input/mobile-input.vue'
  import store from '@/store'
  import {
    GET_USER,
    UPDATE_PROFILE
  } from '@/store/action-types'

  export default {
    components: {
      'flash-message-component': FlashMessage,
      'mobile-input-component': MobileInput
    },

    setup () {
      let router = useRouter()
      let store = useStore()

      let registering = ref(false)
      let errorDialog = ref(null)
      let errorCode = ref('')
      let inputs = ref({
        firstName: '',
        lastName: '',
        mobileNumber: '',
        email: '',
        password: '',
        confirmPassword: ''
      })

      let isMobileNumberValid = computed(() => {
        let mobileNumber = inputs.value.mobileNumber
        if (!mobileNumber) return true
        if (!mobileNumber.split(' ')[1]) return true
        return /^\+[1-9]\d{0,2} \d{1,12}$/.test(mobileNumber)
      })

      let isEmailValid= computed(() => {
        let emailVal = inputs.value.email
        const emailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
        if (!emailVal) return true
        return emailReg.test(emailVal)
      })

      let validate = () => {
        let {
          firstName,
          lastName,
          mobileNumber,
          email
        } = inputs.value

        if (!firstName) return null
        if (!lastName) return null
        if (!/^\+[1-9]\d{0,2} \d{1,12}$/.test(mobileNumber)) return null
        if (!email) return null

        return {
          email,
          profile: {
            firstName,
            lastName,
            mobileNumber,
            email
          }
        }
      }

      let register = async () => {
        let data = validate()
        if (!data) {
          errorCode.value = 'missing'
          errorDialog.value.show()

          return
        }

        let { profile } = data

        try {
          registering.value = true
          await store.dispatch(UPDATE_PROFILE, { profile })

          router.push({ name: 'test' })
        } catch (error) {
          switch (error.code) {
            case 'auth/invalid-email':
              errorCode.value = 'invalid-email'
              break
            case 'auth/email-already-in-use':
              errorCode.value = 'email-already-in-use'
              break
            default:
              errorCode.value = 'unknown'
          }
          errorDialog.value.show()
        } finally {
          registering.value = false
        }
      }

      return {
        registering,
        errorDialog,
        errorCode,
        inputs,
        isMobileNumberValid,
        isEmailValid,
        register
      }
    }
  }
</script>

<style lang='scss' scoped>
  @use 'sass:map';
  @use '@/styles/variables';

  .uxg {
    margin-top: 16px;
    font-weight: map.get(variables.$font-weights, 'bold');
    font-size: map.get(variables.$font-sizes, 'lg');
  }

  .reg {
    margin-top: 24px;
    padding: 24px;
    background-color: white;
    border-radius: 4px;
  }

  .hss {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .nrt {
    width: 100%;

    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  .pcs {
    margin-top: 24px;
    text-align: center;
  }

  .rwy {
    margin-left: 16px;
  }

  @media (min-width: map.get(variables.$breakpoints, 'lg')) {
    .uxg {
      text-align: center;
    }

    .reg {
      margin-top: 32px;
      padding: 32px;
    }

    .hss {
      flex-direction: row;

      &:not(:first-child) {
        margin-top: 32px;
      }
    }

    .nrt {
      &:not(:first-child) {
        margin: 0 0 0 32px;
      }
    }

    .pcs {
      margin-top: 32px;
    }
  }
</style>
